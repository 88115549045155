<template>
  <b-card-body style="padding: 16px">
    <div class="d-flex">
      <div class="image__container">
        <b-img
          :src="products.image_url"
          alt="image"
        />
      </div>
      <div class="product__information w-100 d-flex flex-column justify-content-between">
        <div class="d-flex flex-row justify-content-between">
          <div class="d-flex flex-column justify-content-between mr-1">
            <h1 class="h5 text-dark font-weight-bolder mb-0">
              {{ products.alias_name || '-' }}
            </h1>
            <small
              class="text-dark text-darken-4 size14"
            >{{ products.stock || 0 }} {{ products.unit }}
              <!-- <span class="text-dark h4">&bull;</span>{{ products.specification || '-' }} -->
            </small>
          </div>
          <h1 class="h5 text-dark font-weight-bolder mb-0 d-lg-block d-none d-md-none">
            {{ products.standard_price | formatAmount }}
          </h1>
        </div>
        <h1 class="h5 text-dark font-weight-bolder mb-0 d-lg-none d-md-block mt-25">
          {{ products.standard_price | formatAmount }}
        </h1>
        <div class="d-flex flex-row justify-content-between h-100 align-productss-end mt-1">
          <div
            class="favorite__box border-light border-line"
            role="button"
            @click="favorite(products.uuid, products.is_favorite)"
            id="button--choose__favorite"
          >
            <feather-icon
              v-if="products.is_favorite == false"
              class="text-dark text-darken-5"
              icon="StarIcon"
              size="21"
              :class="{ 'star__filled' : products.is_favorite}"
            />
            <b-img
              v-else
              :src="require('/src/assets/images/icons/star-solid.svg')"
            />
          </div>
          <b-button
            :id="firstIndex == 0 ? 'tour-add-cart' : ''"
            variant="secondary"
            :disabled="products.is_on_stock_opname"
            class="btn-sm"
            @click="detailProduct(products.uuid)"
          >
            <!-- detailProduct(products.uuid) -->
            <b-spinner
              v-if="isLoadingEdit && products.uuid === id"
              small
              variant="primary"
              class="mr-1"
            />
            <feather-icon
              v-else
              icon="ShoppingCartIcon"
              class="mr-1"
            />
            {{ !products.is_on_stock_opname ? 'Tambahkan' : 'Sedang Stok Opname' }}
          </b-button>
        </div>
      </div>
    </div>
  </b-card-body>
</template>

<script>
import {
  BButton, BCardBody, BImg, BSpinner,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

export default {
  components: {
    BButton,
    BCardBody,
    BImg,
    BSpinner,
  },
  props: {
    firstIndex: {
      type: Number,
    },
    is_favorite: {
      type: Boolean,
    },
    products: {
      type: Object,
    },
    detailProduct: {
      type: Function,
    },
    getData: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
    },
    idEdit: {
      type: String,
    },
  },
  setup() {
    return {
      checkPermission, successNotification, errorNotification,
    }
  },
  data() {
    return {
      isLoadingEdit: false,
      id: '',
    }
  },
  watch: {
    isLoading(value) {
      this.isLoadingEdit = value
    },
    idEdit(value) {
      this.id = value
    },
  },
  methods: {
    favorite(id, isFavorite) {
      this.$store.dispatch('cashier/toggleFavorite', {
        uuid: id,
        type: isFavorite === true ? 'remove-favorite' : 'add-favorite',
      }).then(result => {
        successNotification(this, 'Success', result.data.data.is_favorite === true ? 'Produk masuk ke daftar favorite' : 'Produk dihapus dari daftar favorite')
        this.getData()
      })
    },
  },
}
</script>

<style lang="scss">
/* .star__filled {
  background: #F8BE13;
} */
@media screen and (max-height: 800px) {
  .product__information h1 {
    small, h1 {
      font-size: 12px !important;
    }
  }
  svg.feather {
    width: 18px;
    height: 18px;
  }
  .tabs.tabs__cashier .favorite__box {
    width: 24px !important;
    height: 24px !important;
  }
}
</style>
